<template>
  <div style="width: 100%;">
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="title" label="名称" ></el-table-column>
        <el-table-column prop="pic" label="图标" >
          <template #default="s">
            <img v-if="s.row.pic" :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem">
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="s">
            <el-button style="margin-right: 2rem" @click="edit(s.row)" size="small">编辑</el-button>
            <el-popconfirm @confirm="del(s.row)" title="确定删除吗？">
              <el-button slot="reference" size="small" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
          <template #header>
            <el-button @click="editBox=true" size="mini">添加分类</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑分类" width="30rem" :visible.sync="editBox" @close="close">
      <el-form :model="editForm">
        <el-form-item label="分类标题">
          <el-input v-model="editForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分类图标">
          <y_upload_img v-model="editForm.pic"></y_upload_img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
export default {
  name: "list",
  components: {
    y_upload_img,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
      editForm:{
        title:"",
      },
      editBox:false,
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    close(){
      this.editForm = {
        title:"",
      }
    },
    del({id}){
      this.$api.plugin.shop.cateDel({id}).then(()=>{
        this.loadList();
      })
    },
    edit(item){
      this.editForm = {
        ...item
      }
      this.editBox = true;
    },
    submit(){
      this.$api.plugin.shop.cateEdit(this.editForm).then(()=>{
        this.editBox = false;
        this.$message.success("操作成功");
        this.loadList();
      })
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.shop.cateSearch({
        page: this.page,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>